<template>
  <div>
    <ViewTypesContacts />
  </div>
</template>

<script>
  import ViewTypesContacts from '@/components/contacts/ViewTypesContacts.vue';

  export default {
    components: {
      ViewTypesContacts,
    }
  }
</script>