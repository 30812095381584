<template>
  <div>
    <header class="styleContacts">
      <h1>Tipo de contato</h1>
      <router-link
        style="width: 191px; height: 0px; margin-right: 20px;"
        :to="{ name: 'addOrUpdateContacts', params: { activeRegister } }"
      >
        <button class="btn-addNewContacts">Incluir</button>
      </router-link>
    </header>
    <section class="searchPbm">
      <h6>Pesquisa de tipo de contato</h6>
      <div>
        <img src="@/assets/img/lupa.png" alt="Lupa">
        <input type="text">
      </div>
    </section>
    <main class="contentContacts">
      <table class="tableContacts">
        <tr>
          <div style=
          "
            display: flex;
            justify-content: space-between;
            width: 200px;
          ">
            <th>Contato</th>
            <th>Descrição</th>
          </div>
            <th style="font-size: 0.8em; width: 50px;">Vizualizar</th>
            <th style="font-size: 0.8em; width: 50px;">Editar</th>
            <th style="font-size: 0.8em; width: 50px;">Excluir</th>
        </tr>
        <tr style="background-color: #e6e6e6;">
          <div style=
          "
            display: flex;
            justify-content: space-between;
            width: 200px;
          ">
            <td>TPN12461030</td>
            <td style="margin-left: 65px;">Teste</td>
          </div>
          <td>
            <img width="28px" height="28px" src="@/assets/img/view.svg" alt="Icone de vizualização">
          </td>
          <td>
            <img width="18px" height="18px" src="@/assets/img/editar.svg" alt="Icone de editar">
          </td>
          <td>
            <img width="35px" height="35px"  src="@/assets/img/excluir.svg" alt="Icone de excluir">
          </td>
        </tr>
      </table>
    </main>
    <!-- <div class="content-btn-addReturn">
      <router-link style="width: 0px" to="/dashboard">
        <button class="contacts-btn-return">
          {{ $t("sectionRegisters.backDashbord") }}
        </button>
      </router-link>
      <router-link
        style="width: 0px"
        :to="{ name: 'addOrUpdateContacts', params: { activeRegister } }"
      >
        <button class="contacts-btn-add">
          {{ $t("sectionTypeContact.addContact") }}
        </button>
      </router-link>
    </div>
    <h1 class="titleViewContact">{{ $t("sectionTypeContact.typeContact") }}</h1>
    <label class="content-searchContact">
      <span>{{ $t("sectionTypeContact.searchTypeContact") }}</span>
      <input v-model="search" type="text" />
      <button @click="searchContact">
        {{ $t("sectionSiproquim.search") }}
      </button>
    </label>
    <table class="content-tableContact">
      <tr class="header-table">
        <th>{{ $t("sectionTypeContact.description") }}</th>
      </tr>
      <tr
        class="items-table"
        v-for="(contacts, index) in stateContacts"
        :key="index"
      >
        <td>{{ contacts.descricao }}</td>
        <span class="content-btn-contactEditOrDelete">
          <router-link
            :to="{
              name: 'addOrUpdateContacts',
              params: { activeEdit, contacts },
            }"
          >
            <button class="editStyle">
              {{ $t("sectionRegisters.edit") }}
            </button>
          </router-link>
          <button class="deleteStyle" @click="deleteContacts(contacts)">
            {{ $t("sectionRegisters.delete") }}
          </button>
        </span>
      </tr>
    </table>
    <section class="content-pageContact">
      <button class="backPage" @click="backPage">Página Anterior</button>
      <button class="nextPage" @click="nextPage">Próxima Página</button>
    </section> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ViewTypesContacts",
  data() {
    return {
      activeRegister: true,
      activeEdit: true,
      search: "",
      foundContact: "",
      PageCurrent: 1,
    };
  },

  mounted() {
    this.$store.dispatch("stateTypesContacts/totalTypesContacts");
  },

  methods: {
    deleteContacts(contacts) {
      const { stateContacts } = this;

      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      axios
        .post(
          `${process.env.VUE_APP_BASE_URL}/tipoContato/remover/${contacts.id}`,
          true,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        )
        .then(() => console.log("Deu tudo certo."))
        .catch(() => console.log("Ocorreu um erro."));

      const findContacts = stateContacts.find(
        (element) => element.id == contacts.id
      );
      const indexContacts = stateContacts.indexOf(findContacts);

      stateContacts.splice(indexContacts, 1);
    },

    searchContact() {
      const checkSearch = this.search == "" || this.search == " ";

      const findContact = () => {
        const filterContact = this.stateContacts.filter((element) => {
          return element.descricao.toLowerCase() == this.search.toLowerCase();
        });

        this.foundContact = filterContact;
      };

      if (!checkSearch) {
        findContact();

        if (this.foundContact) {
          return this.$store.commit(
            "stateTypesContacts/SET_TYPES_CONTACTS",
            this.foundContact
          );
        } else {
          this.$store.dispatch("stateTypesContacts/totalTypesContacts");
          return alert("Nenhum tipo de contato encontrado");
        }
      } else {
        return alert("Nenhum tipo de contato encontrado");
      }
    },

    async backPage() {
      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      this.PageCurrent--;

      if (this.PageCurrent >= 1) {
        const reqPages = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/tipoContato/obter-grid/${this.PageCurrent}`,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        );

        return this.$store.commit(
          "stateTypesContacts/SET_TYPES_CONTACTS",
          reqPages.data.itens
        );
      } else {
        alert("Você já esta na primeira página");

        return (this.PageCurrent = 1);
      }
    },

    async nextPage() {
      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      this.PageCurrent++;

      const pages = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/tipoContato/obter-grid/1`,
        {
          headers: { Authorization: `Bearer ${authStr}` },
        }
      );

      if (this.PageCurrent <= pages.data.totalDePaginas) {
        const reqPages = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/tipoContato/obter-grid/${this.PageCurrent}`,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        );

        return this.$store.commit(
          "stateTypesContacts/SET_TYPES_CONTACTS",
          reqPages.data.itens
        );
      } else {
        alert("Alcançado o limite de páginas");

        return (this.PageCurrent = pages.data.totalDePaginas);
      }
    },
  },

  watch: {
    search() {
      this.$store.dispatch("stateTypesContacts/totalTypesContacts");
    },
  },

  computed: {
    stateContacts() {
      const { typesContacts } = this.$store.state.stateTypesContacts;

      return typesContacts;
    },
  },
};
</script>

<style scoped>
/* /* main {
  position: absolute;
  padding: 10px 50px 0 150px;
  left: 301px;
  width: 84%;
  margin-left: 0%;
} */

/* .content-btn-addReturn {
  display: flex;
}
.contacts-btn-return {
  padding: 2px;
  width: 150px;
  margin-left: 10px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.contacts-btn-add {
  padding: 2px;
  width: 150px;
  margin-left: 910px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.titleViewSipro {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.content-searchContact {
  padding: 10px;
  /* border: 1px red solid; */
/* } */
/* .content-searchContact span {
  font-weight: bold;
  font-size: 1.2em;
} */

/* .content-searchContact input {
  margin-left: 5px;
  padding: 2px;
}

.content-searchContact button {
  margin-left: 4px;
  padding: 2.5px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.content-tableContact {
  border: 1px black solid;
}

.content-tableContact .header-table {
  font-weight: bold;
  font-size: 1.1em;
  border: 2px black solid;
}

.content-tableContact .items-table {
  /* margin: 50px; */
  /* border: 0.8px gray solid; */
/* } */

/* .content-tableContact .items-table:hover {
  background-color: gray;
  color: black;
  font-weight: bold;
}

.content-btn-contactEditOrDelete .editStyle {
  margin: 3px;
  padding: 1px;
  color: black;
  border: 1.5px black solid;
  border-radius: 5px;
}

.content-btn-contactEditOrDelete .deleteStyle {
  margin-left: 3px;
  padding: 1px;
  color: black;
  background-color: red;
  border: 1px black solid;
  border-radius: 5px;
} */

/* .content-pageContact {
  text-align: center;
  margin-top: 10px; */
/* }
.content-pageContact .backPage {
  padding: 3px;
  margin-right: 10px;
  font-size: 1em;
  font-weight: bold;
  background-color: turquoise;
  border: 1px solid;
  border-radius: 5px;
} */

/* .content-pageContact .nextPage {
  padding: 3px;
  margin-left: 10px;
  font-size: 1em;
  font-weight: bold;
  background-color: turquoise;
  border: 1px solid;
  border-radius: 5px;
} */
</style>
